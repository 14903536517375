.brands-container {
    width: 100%;
    min-height: 174px;
    background-color: $color-6;
    border-top: 2px solid $color-3;
    @include mappy-bp(mediumish) {
        min-height: 170px;
        background-color: $color-1;
        border-top: 2px solid $color-6;
    }

    .brands {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 20px;
        @include mappy-bp(mediumish) {
            padding-top: 35px;
        }
    }

    article {
        display: flex;
        align-items: center;
        width: 33.33%;
        height: 60px;
        margin-right: 0;
        margin-bottom: 0;
        @include mappy-bp(medium) {
            width: 200px;
        }
        @include mappy-bp(mediumish) {
            height: 100px;
            margin-right: 18px;
            margin-bottom: 10px;
        }
    }

    a {
        width: 100%;
    }

    img {
        display: block;
        max-width: 100px;
        max-height: 40px;
        margin: 0 auto;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        filter: gray;

        @include mappy-bp(mediumish) {
            max-width: 120px;
            max-height: 60px;
        }
    }
}