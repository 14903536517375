/*********************************************
:: Variables                      
********************************************/

//Mappy Breakpoints: https://github.com/zellwk/mappy-breakpoints
    
$breakpoints: (
    tiny: 350px,
    small: 450px,
    smallish: 600px,
    medium: 768px,
    mediumish: 1024px,
    large: 1160px,
    largeish: 1280px,
    huge: 1440px, 
    bigmac: 1920px
);

//Breakpoint Sizes
$mediumish : 1024px;
$tablet : 600px;
$iphone5: 350px;

/*******************************************/
//Fonts

// Base Fonts
$font-family-1: azo-sans-web, sans-serif;
$font-family-2: 'Open Sans', sans-serif;

$font-icon: "icomoon";

// Fonts Weights
$font-light: 300;
$font-normal: 400;
$font-semibold: 500;
$font-xtrasemibold: 600;
$font-bold: 700;
$font-extrabold: 900;

/*******************************************/
//Colors

// Brand Colors
$color-1: #ffffff;
$color-2: #0d0d0b;
$color-3: #fff100;
$color-4: #7f7f7f;
$color-5: #dedede;
$color-6: #f1f1f1;

$color-7: #dedede;

$white: #ffffff;
$black: #000000;

// Shades of Grey
$color-grey: #979797;
$color-medium-grey: #ccc;
$color-dark-grey: #212121;
$color-light-grey: #ececec;

// Links
$color-link: $color-1;
$color-link-hover: darken($color-link, 12%);

// User Feedback
$color-message: $color-medium-grey;
$color-attention: #FF0000; // $color-4;
$color-success: #38B449;

// Social Media
$color-facebook: #3b5998;
$color-twitter: #55acee;
$color-vimeo: #1ab7ea;
$color-youtube: #cd201f;
$color-gplus: #DC4E41;

/*******************************************/
//Page Height Calculator
$header-height-desktop: 110px;
$header-height-mobile: 80px;

$footer-height-desktop: 275px;
$footer-height-mobile: 125px;

//*******************************************/
//Placeholder image

$placeholder-image: "https://www.scandichotels.com/Static/img/placeholders/image-placeholder_3x2.svg";