.open-menu {
    .site-header {
        position: fixed;
        top: 0;
        height: 83px;
        border-bottom: 3px solid $color-3;
        
        .hamburger {
            &.open-close-icon {
                display: none;
            }

            &.menu-close-icon {
                display: block;
            }
        }
    }
}

.site-header {
    position: relative;
    z-index: 99;
    width: 100%;
    height: $header-height-mobile;
    background-color: $color-2;
    border-bottom: none;

    @include mappy-bp(mediumish) {
        height: $header-height-desktop;
        border-bottom: 5px solid $color-3;
        &:after {
            content: '';
        }
    }
    @include mappy-bp(huge) {
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;

            width: 20%;
            height: 100%;
            background-color: $color-3;

            @include mappy-bp(bigmac) {
                width: 28%;
            }
        }
    }

    .content-max-width {
        height: 100%;
        padding: 0 20px;
        @include mappy-bp(mediumish) {
            padding: 0;
        }
    }

    .site-inner-container {
        display: block;
        height: 100%;
        @include mappy-bp(mediumish) {
            display: flex;    
        }

        .grid-left {
            position: relative;
            width: 100%;
            @include mappy-bp(mediumish) {
               width: calc(100% - 780px);
            }

            .logo {
                position: absolute;
                top: 26px;
                left: 0;
                @include mappy-bp(mediumish) {
                    top: 21px;
                    left: 10.4%;
                }

                img {
                    width: 174px;
                    height: 37px;
                    @include mappy-bp(mediumish) {
                        width: 344px;
                        height: 64px;
                    }
                }
            }
        }

        .grid-right {
            width: 100%;
            height: 100%;
            @include mappy-bp(mediumish) {
                width: 780px;
                padding-left: 50px;
            }
            @include mappy-bp(1200px) {
                padding-left: 0;
            }

            nav {
                height: 100%;
            }

            ul.desktop {
                height: 100%;

                li {
                    display: inline-block;
                    margin-right: 26px;
                    margin-bottom: 0;
                    padding-top: 17px;

                    &:nth-child(3) {
                        margin-right: 27px;
                    }
                    &:nth-child(4) {
                        margin-right: 17px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        color: $white;
                        font-size: 15px;
                        font-weight: $font-bold;
                        line-height: 20px;

                        &:hover, &.selected {
                            color: $color-3;
                        }
                    }

                    &.phone {
                        position: relative;
                        width: 289px;
                        height: 100%;
                        padding-right: 30px;
                        text-align: right;
                        background-color: $color-3;
                        
                        @include mappy-bp(1200px) {
                            width: 339px;
                            padding-right: 71px;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 0;
                            height: 0;
                            border-right: 37px solid transparent;
                            border-bottom: 105px solid $color-2;
                        }

                        span {
                            display: block;
                            position: relative;
                            top: 13px;
                        }

                        a {
                            position: relative;
                            top: 16px;

                            font-size: 20px;
                            font-weight: $font-bold;
                            line-height: 20px;
                            color: #0f1216;
                        }
                    }
                }
            }
        }     
    }

    .hamburger {
        display: block;
        position: absolute;
        top: 33px;
        right: 23px;

        &:focus {
            outline: none;
        }

        &.menu-close-icon {
            display: none;
            top: 30px;
        }

        span {
            display: block;
            width: 24px;
            height: 1px;
            background-color: $white;
            margin-bottom: 5px;

            &.icon-cross {
                height: 18px;
                background-color: unset;

                font-size: 18px;
                text-align: center;
                color: $white;
            }
        }
    }
}