//Usage:
// @include bg(cover)
@mixin bg($setting, $background: ""){
    @if $setting == cover {
        background-position: center;
        background-size: cover;
    } @else if $setting == contain {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    @if $background != ""{
        background-image: url( $background );
    } @else {
        background-image: url( $placeholder-image );
    }
}

//Basic colour change placeholder
@mixin placeholder-color($color: #666666){
    &::-webkit-input-placeholder { color: $color; }
    &::-moz-placeholder { color: $color; }
    &:-ms-input-placeholder { color: $color; }
    &::placeholder { color: $color; }
}

//Advanced placeholder
//Can be used to change all the properties (font-family, size, colour... )
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &::placeholder {
        @content;
    }
}


@mixin no-scroller(){
    &::-webkit-scrollbar { width: 0 !important; }
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}