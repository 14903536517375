.site-footer {
    position: relative;
    width: 100%;
    height: $footer-height-mobile;
    background-color: $color-6;
    
    @include mappy-bp(mediumish) {
        height: $footer-height-desktop;
    }

    #container {
        padding-top: 3px;
        padding-left: 5px;
        text-align: center;
        @include mappy-bp(mediumish) {
            padding-top: 60px;
            padding-left: 0;
        }

        li {
            margin-bottom: 0;
            @include mappy-bp(mediumish) {
                margin-bottom: 9px;
            }
            &:nth-of-type(2) {
                margin-bottom: 3px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            &.phone {
                font-size: 21px;
                font-weight: $font-bold;
                line-height: 25px;
                color: $color-4;
                @include mappy-bp(mediumish) {
                    font-size: 25px;
                }
            }

            &.email {
                padding-left: 4px;
                font-size: 14px;
                font-weight: $font-normal;
                line-height: 20px;
                color: $color-2;
                @include mappy-bp(mediumish) {
                    font-size: 15px;
                    padding-left: 0;
                }
            }
        }

        p {
            margin: 0;

            font-size: 14px;
            font-weight: $font-normal;
            line-height: 20px;
            color: $color-2;
            @include mappy-bp(mediumish) {
                font-size: 15px;
            }
        }
    }

    .footer-copyright {
        width: 100%;
        margin-top: 15px;
        padding: 0 0 15px 5px;

        text-align: center;
        background-color: $color-6;
        @include mappy-bp(mediumish) {
            height: 1px;
            margin-top: 39px;
            padding: 0 15px;
            background-color: $color-4;
        }

        .content-narrow-width-3 {
            padding-top: 0;
            @include mappy-bp(mediumish) {
                padding-top: 20px;
            }
        }

        p.copyright {
            margin-bottom: 0;
            font-size: 11px;
            font-weight: $font-light;
            line-height: 15px;
            color: $color-2;
            @include mappy-bp(mediumish) {
                margin-bottom: 15px;
                font-size: 10px;
                float: left;
            }
        }

        a.attribution {
            @extend p.copyright;
            
            @include mappy-bp(mediumish) {
                float: right;
            }

            @include mappy-bp(max-width mediumish) {
                margin-right: 0;
                margin-bottom: 0;
            }
        }
    }
}
