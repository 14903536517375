/*********************************************
:: Layout
********************************************/

//
.titanic {
    float: none;
}

// Remove blue highlight on click
* {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

/*******************************************/
// Main <section class="main" role="main">
.main {
    display: block;
    min-height: calc(100vh - #{$header-height-mobile + $footer-height-mobile});

    @include mappy-bp(mediumish){
        min-height: calc(100vh - #{$header-height-desktop + $footer-height-desktop});
    }
}

/*******************************************/
// Main <aside class="sidebar" role="complementary"> element
.sidebar {
    float: left;
    width: 100%;
    padding: 20px 15px 20px 15px;

    @include mappy-bp(mediumish){
        padding: 45px 0px 45px 0px;
    }
}

/*******************************************/
// Clear
.clear-after {
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

/*******************************************/
// Content Max Width
.content-max-width {
    position: relative;
    width: 100%;
    padding: 0 5%;

    @include mappy-bp(mediumish){
        max-width: 1440px;
        margin: 0 auto;
        padding: 0;
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

/*******************************************/
// Content Slider Width
.content-slider-width {
    position: relative;
    padding: 0;

    @include mappy-bp(mediumish){
        max-width: 940px;
        margin: 0 auto;
    }
    @include mappy-bp(large){
        max-width: 940px;
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

/*******************************************/
// Narrow Content Max Width
.content-narrow-width {
    position: relative;
    padding: 0 20px;

    @include mappy-bp(mediumish){
        max-width: 970px;
        margin: 0 auto;
        padding: 0;
    }
    @include mappy-bp(large){
        max-width: 970px;
        padding: 0;
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

/*******************************************/
// Narrow Content Max Width 2
.content-narrow-width-2 {
    position: relative;
    width: 100%;
    padding: 0 5%;
    
    @include mappy-bp(mediumish){
        max-width: 1305px;
        margin: 0 auto;
        padding: 0;
    }
    @include mappy-bp(large){
        max-width: 1305px;
        padding: 0;
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

/*******************************************/
// Narrow Content Max Width 3 (sample footer content width)
.content-narrow-width-3 {
    position: relative;
    padding: 0;
    
    @include mappy-bp(mediumish){
        max-width: 1170px;
        margin: 0 auto;
    }
    @include mappy-bp(large){
        max-width: 1170px;
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

/*******************************************/
// Breakpoints
.mob, .mobile {
    @include mappy-bp(mediumish){
        display: none !important;
    }
}

.desk, .desktop {
    @include mappy-bp(max-width mediumish){
        display: none !important;
    }
}

.error-container {
    margin-top: 50px;
    margin-left: 100px;

    p {
        font-size: 17px;
        font-weight: $font-semibold;
    }
}

/*******************************************/
// Flex
.flex {
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    @include mappy-bp(medium) {
        flex-direction: row;
    }
    @include mappy-bp(large) {
        padding: 0;
    }
}

.flex-reverse {
    flex-direction: column-reverse;
    @include mappy-bp(medium) {
        flex-direction: row;
    }
}

/*******************************************/
// Background for modal pop ups
.overlay {
    display: none;
    position: fixed; 
    width: 100%;
    height: 100%;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(33, 33, 33, 0.60);
    z-index: 99;
    cursor: pointer;
}


/********** SELECT2 *********/

.select2-container {
    width: 100% !important;
}

.select2-container--default {
    &.select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                height: 26px;
                position: absolute;
                top: -4px!important;
                right: 15px!important;
                width: 20px;
                background: url('/images/icons/dropdown.svg');
                background-repeat: no-repeat;
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }
    }
    .select2-selection--single, .select2-selection--default {
        width: 100% !important;
        border-radius: 0px !important;
        border: 1px solid $color-3;
        .select2-selection__arrow {
            height: 26px;
            position: absolute;
            top: 18px !important;
            right: 2px !important;
            width: 20px;
            background: url('/images/icons/dropdown.svg');
            background-repeat: no-repeat;
        }
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: $color-1 !important;
    }
    .select2-results__option {
        text-transform: uppercase;
    }
    .select2-selection--single {
        .select2-selection__rendered {
            color: $color-4 !important;
            font-size: 10px !important;
            letter-spacing: 1.5px !important;
            text-transform: uppercase !important;
            line-height: 16px !important;
            padding-top: 11px !important;
            font-weight: $font-normal !important;
        }
    }
}