/*********************************************
:: Products & Services Page                   
********************************************/
.page-products-services {
    .title-module {
        margin-bottom: 0;
    }
}
/********************************************/
.product-service-container {
    min-height: 560px;
    padding: 50px 20px;
    @include mappy-bp(mediumish) {
        min-height: 580px;
        padding: 130px 20px 90px;
    }
    @include mappy-bp(largeish) {
        padding: 130px 0 90px;
    }

    &:nth-child(odd) {
        background-color: $color-2;
        @include mappy-bp(mediumish) {
            padding: 90px 20px;
        }
        @include mappy-bp(largeish) {
            padding: 90px 0;
        }

        article {
            @include mappy-bp(mediumish) {
                flex-direction: row-reverse;
            }

            .col-l {
                img {
                    top: unset;
                    right: 0;
                    bottom: 0;
                    left: unset;
                    @include mappy-bp(mediumish) {
                        bottom: -42px;
                        right: 18px;
                    }
                    @include mappy-bp(large) {
                        right: 38px;
                    }
                }
            }

            .col-r {
                @include mappy-bp(mediumish) {
                    padding: 78px 20px 10px 88px; 
                }

                ul {
                    li {
                        color: $color-1;
                    }
                }
            }
        }
    }
    /********************************************/
    article {
        position: relative;
        flex-direction: column;
        
        max-width: 1170px;
        min-height: 360px;
        padding: 0;
        
        border: 2px solid $color-3;
        @include mappy-bp(mediumish) {
            flex-direction: row;
            padding: 0;
        }

        .col-l {
            position: relative;
            width: 100%;
            text-align: center;

            img {
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include mappy-bp(medium) {
                    width: 660px;
                    height: 360px;
                }
                @include mappy-bp(mediumish) {
                    position: absolute;
                    top: -41px;
                    left: 18px;
                }
                @include mappy-bp(large) {
                    left: 38px;
                }
            }
        }

        .col-r {
            width: 100%;
            margin: 0;
            padding: 20px 0 20px 35px;
            @include mappy-bp(mediumish) {
                width: 590px;
                padding: 78px 20px 10px 75px;
            }
            @include mappy-bp(large) {
                padding: 78px 20px 10px 21px;
            }

            ul {
                margin-bottom: 0;

                li {
                    margin-bottom: 5px;

                    font-size: 20px;
                    font-weight: $font-bold;
                    line-height: 30px;
                    cursor: pointer;
                    @include mappy-bp(mediumish) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    /********************************************/
}
/********************************************/
.product-service-modal {
    display: none;
    position: absolute;
    top: -10%;
    right: 0;
    bottom: 0;
    left: -20px;
    z-index: 100;
    
    width: calc(100% + 40px);
    height: 100%;
    margin: 0 auto;
    padding: 0;

    @include mappy-bp(mediumish) {
        position: fixed;
        top: 310px;
        left: 0;

        width: 970px;
        max-height: 445px;
    }

    .modal-header {
        min-height: 80px;
        padding: 20px 50px 0 20px;
        background-color: $color-2;
        @include mappy-bp(mediumish) {
            padding: 20px 40px 0;
        }

        h2 {
            margin: 0;
            padding-bottom: 10px;
            
            font-size: 25px;
            font-weight: $font-bold;
            letter-spacing: 1.5px;
            line-height: 30px;
            color: $color-1;
            text-transform: uppercase;
        }

        img {
            position: absolute;
            top: 27px;
            right: 20px;
            width: 25px;
            height: 23px;
            margin: 0;
            cursor: pointer;
            @include mappy-bp(mediumish) {
                right: 39px;
            }
        }
    }
    /********************************************/
    .modal-body {
        padding: 34px 0 40px;
        background-color: $color-1;

        .col-left {
            width: 100%;
            padding-top: 0;
            margin-bottom: 20px;
            @include mappy-bp(medium) {
                width: 80%;
                margin-bottom: 0;
                padding-top: 28px;
            }
            @include mappy-bp(mediumish) {
                width: 350px;
            }

            img {
                width: 232px;
                height: 197px;
                margin: 0 auto;
            }
        }

        .col-right {
            width: 100%;
            padding: 0 15px;
            @include mappy-bp(mediumish) {
                width: calc(100% - 350px);
                padding: 0;
            }

            .leadcopy {
                margin-bottom: 20px;
                font-size: 20px;
                line-height: 32px;
            }

            .wysiwyg {
                margin-bottom: 40px;

                p {
                    font-size: 15px;
                }
            }

            .button {
                width: 145px;
            }
        }
    }
    /********************************************/
}