body.open-menu {
    #mobile-menu {
        right: 0;
    }
}

#mobile-menu {
    position: fixed;
    top: 72px;
    bottom: 0; 
    right: 0;
    right: -326px;
    width: 326px;
    background: $color-2;
    z-index: 9999;
    transition: all .25s;
    -webkit-backface-visibility: hidden;
    margin-top: 11px;

    @include mappy-bp(mediumish){
        display:none;
    }

    /* .menu-close-icon {
        display: none;
        position: absolute;
        top: 33px;
        right: 23px;
        border: none;
        outline: none;

        color: $white;
    } */

    .menu-content {
        overflow-y: auto;
        &::-webkit-scrollbar { 
            display: none; 
        }

        ul {
            padding: 36px 24px 0 85px;
            text-align: right;

            li {
                margin: 0;
                padding: 0 0 9px;

                a {
                    font-size: 21px;
                    font-weight: $font-bold;
                    line-height: 20px;
                    color: $color-1;

                    &.selected {
                        color: $color-3;
                    }
                }
            }
            //-----------------------------------------------------
            &.bottom {
                margin-top: 23px;
                padding: 0 0 0 103px;

                li {
                    position: relative;
                    min-height: 52px;
                    padding-top: 17px;
                    padding-right: 26px;
                    background-color: $color-3;

                    &:before {
                        content: '';
                        position: absolute;

                        top: 0;
                        left: 0;
                        width: 0;
                        height: 0;
                        border-right: 25px solid transparent;
                        border-bottom: 52px solid $color-2;
                    }

                    a {
                        color: #0f1216;
                    }
                }
            }
        }
    }
}