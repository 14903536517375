/*********************************************
:: Contact Us Page                   
********************************************/
.contact-container {
    margin-bottom: 30px;
    @include mappy-bp(mediumish) {
        margin-bottom: 90px;
    }

    .content-narrow-width-3 {
        &:before {
            content: '';
        }
        @include mappy-bp(mediumish) {
            &:before {
                content: '';
                position: absolute;
                top: -40px;
                left: calc((100% - 1170px)/2);
                right: unset;
                z-index: -1;

                width: 415px;
                min-height: 365px;

                background-color: $color-3;
                @include mappy-bp(large) {
                    left: unset;
                    right: calc(100% - (100% - 1170px)/2);
                    width: 470px;
                }
            }
        }
    }

    aside {
        position: relative;
        width: 100%;
        margin-bottom: 35px;

        @include mappy-bp(medium) {
            width: 400px;
        }
        @include mappy-bp(mediumish) {
            width: 430px;
        }
        @include mappy-bp(large) {
            width: 600px;
        }

        &:before {
            content: '';
            position: absolute;
            top: -10px;
            left: -20px;
            z-index: -1;

            width: 375px;
            min-height: 325px;

            background-color: $color-3;
            @include mappy-bp(mediumish) {
                top: -40px;
                left: 0;

                width: 415px;
                min-height: 365px;
            }
            @include mappy-bp(large) {
                width: 470px;
            }
        }

        .contacts {
            margin-bottom: 20px;
            @include mappy-bp(mediumish) {
                margin-bottom: 40px;
            }

            .phone {
                font-size: 18px;
                font-weight: $font-normal;
                letter-spacing: 2px;
                line-height: 25px;
                text-transform: uppercase;
                color: $color-2;   
                @include mappy-bp(mediumish) {
                    font-size: 23px;
                }
            }

            span {
                @extend .phone;
                margin-right: 10px;
                letter-spacing: 0;
                color: $color-4;

                &:last-of-type {
                    margin-left: 20px;
                    @include mappy-bp(mediumish) {
                        margin-left: 34px;
                    }
                }
            }

            .email {
                display: block;
                margin-top: 15px;

                font-size: 17px;
                font-weight: $font-bold;
                line-height: 25px;
                color: $color-2;
                @include mappy-bp(mediumish) {
                    margin-top: 25px;
                    font-size: 21px;
                }
            }
        }

        .locations {
            h6 {
                margin-bottom: 5px;
                font-size: 14px;
                font-weight: $font-bold;
                letter-spacing: 2px;
                line-height: 25px;
                color: $color-4;
                text-transform: uppercase;
                @include mappy-bp(mediumish) {
                    font-size: 15px;
                }
            }

            p {
                margin-bottom: 20px;
                @include mappy-bp(mediumish) {
                    margin-bottom: 35px;
                }
            }
        }

        .view-map-btn {
            border: 1px solid #0d0d0b;
            background-color: transparent;
        }
    }
    /********************************************/
    article {
        width: 100%;
        margin-top: -4px;
        @include mappy-bp(medium) {
            width: calc(100% - 400px);
        }
        @include mappy-bp(mediumish) {
            width: calc(100% - 430px);
        }
        @include mappy-bp(large) {
            width: calc(100% - 600px);
        }
    }
}
/********************************************/
.map-container {
    height: 380px;

     @include mappy-bp(max-width mediumish) {
        display: none;
        position: fixed;
        top: 170px;
        right: 0;
        left: 0;
        z-index: 100;

        width: 95%;
        margin: 0 auto;
    }

    .map-area {
        width: 100%;
        z-index: 100;

        .close-map {
            display: none;

            @include mappy-bp(max-width mediumish) {
                display: block;
                position: absolute;
                top: 19px;
                right: 21px;
                z-index: 101;

                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }

        .map {
            width: 100%;
            height: 380px;
        }
    }
}