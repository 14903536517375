/*********************************************
:: Home Page                   
********************************************/
.page-home {
    .hero-container {
        position: relative;
        width: 100%;
        margin-bottom: 0;
        @include mappy-bp(mediumish) {
            margin-bottom: 35px;

            &:after {
                content: '';
                position: absolute;
                bottom: -50px;
                right: 0;

                width: 20%;
                height: 325px;
                background-color: $color-3;
                @include mappy-bp(large) {
                    bottom: -40px;
                }
                @include mappy-bp(bigmac) {
                    width: 28%;
                }
            }
        }

        .slide {
            position: relative;
            width: 100%;
            min-height: 224px; 
            
            @include bg(cover);

            @include mappy-bp(mediumish){
                min-height: 400px;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;
                background-color: #0d0d0b;
                opacity: 0.5;
            }
        }

        .slide-text {
            position: absolute;
            top: 23px;
            left: 24px;
            z-index: 2;
            width: 90%;

            @include mappy-bp(mediumish){
                top: 50px;
                width: 570px;
            }
            @include mappy-bp(large) {
                top: 105px;
            }
            @include mappy-bp(largeish) {
                left: 0;
            }

            h1, h2 {
                margin-bottom: 16px;
                font-size: 34px;
                font-weight: $font-bold;
                line-height: 40px;
                color: white;

                @include mappy-bp(mediumish) {
                    font-size: 59px;
                    line-height: 65px;
                }
            }

            p {
                font-size: 14px;
                font-weight: $font-extrabold;
                line-height: 25px;
                letter-spacing: 1.5px;
                color: $color-3;
                text-transform: uppercase;

                @include mappy-bp(mediumish){
                    font-size: 16px;
                    letter-spacing: 2px;
                }
            }
        }
    }
    /********************************************/
    .promo-container {
        position: relative;
        width: 100%;
        height: 233px;
        padding: 40px 55px 40px 45px;
        background-color: $color-3;
        @include mappy-bp(mediumish) {
            width: 505px;
            height: 325px;
        }

        &.desktop {
            position: absolute;
            right: calc((100% - 1024px)/2);
            bottom: -50px;
            z-index: 1;
            
            @include mappy-bp(large) {
                right: calc((100% - 1160px)/2);
                bottom: -40px;
            }
            @include mappy-bp(largeish) {
                right: calc((100% - 1280px)/2);
            }
            @include mappy-bp(huge) {
                right: calc((100% - 1440px)/2);
            }
        }

        &.mobile {
            position: relative;
            right: 0;
            bottom: 0;
            padding: 30px 28px 20px;
        }

        .heading {
            margin-bottom: 14px;
            
            font-size: 22px;
            font-weight: $font-extrabold;
            letter-spacing: 1.3px;
            line-height: 25px;
            text-transform: uppercase;

            @include mappy-bp(mediumish) {
                margin-bottom: 15px;
                font-size: 24px;
                letter-spacing: 2px;
                line-height: 30px;
            }
        }

        p {
            margin-bottom: 36px;            
            font-size: 12px;
            font-weight: $font-normal;
            line-height: 15px;
            @include mappy-bp(mediumish) {
                margin-bottom: 35px;
            }
        }

        .read-more img {
            width: 38px;
            cursor: pointer;
            @include mappy-bp(mediumish) {
                width: 33px;
            }
        }

        .promo-hero {
            position: absolute;
            right: 0;
            bottom: -10px;
            width: 156px;
            height: 164px;
            @include mappy-bp(mediumish) {
                bottom: -80px;
                width: 279px;
                height: 278px;
            }
        }
    }
    /********************************************/
    section.product-container {
        min-height: 455px;
        padding: 0;
        @include mappy-bp(mediumish) {
            min-height: 555px;
            padding: 130px 20px 90px;
        }
        @include mappy-bp(largeish) {
            padding: 130px 0 90px;
        }

        &:nth-of-type(even) {
            border-top: 2px solid $color-3;
            border-bottom: 2px solid $color-3;
            background-color: $color-2;
            @include mappy-bp(mediumish) {
                border: 0;
                padding: 90px 20px 130px;
            }
            @include mappy-bp(largeish) {
                padding: 90px 0 130px;
            }

            article {
                min-height: 335px;
                @include mappy-bp(mediumish) {
                    flex-direction: row-reverse;
                }

                .col-l {
                    img {
                        top: unset;
                        right: 0;
                        bottom: 0;
                        left: unset;
                        @include mappy-bp(medium) {
                            width: 530px;
                            height: 335px;
                        }
                        @include mappy-bp(mediumish) {
                            bottom: -42px;
                            right: 18px;
                        }
                        @include mappy-bp(large) {
                            right: 38px;
                        }
                    }
                }

                .col-r {
                    @include mappy-bp(mediumish) {
                        padding: 58px 10px 20px 68px; 
                    }

                    .heading {
                        color: $color-1;
                    }

                    ul {
                        li {
                            color: $color-1;
                        }
                    }
                }
            }
        }
        /********************************************/
        article {
            position: relative;
            flex-direction: column-reverse;

            max-width: 1170px;
            min-height: 360px;
            padding: 0;

            border: none;
            @include mappy-bp(mediumish) {
                flex-direction: row;
                padding: 0;
                border: 2px solid $color-3;
            }

            .col-l {
                position: relative;
                width: 100%;
                text-align: center;
                @include mappy-bp(mediumish) {
                    height: auto;
                }

                img {
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    @include mappy-bp(smallish) {
                        width: 530px;
                        height: 360px;
                    }
                    @include mappy-bp(mediumish) {
                        position: absolute;
                        top: -41px;
                        left: 18px;
                    }
                    @include mappy-bp(large) {
                        left: 38px;
                    }
                }
            }

            .col-r {
                width: 100%;
                margin: 0;
                padding: 26px 24px 35px;
                @include mappy-bp(mediumish) {
                    width: calc(100% - 295px);
                    padding: 58px 0 25px;
                }

                .heading {
                    margin-bottom: 10px;
                    padding-top: 4px;

                    font-size: 21px;
                    font-weight: $font-extrabold;
                    line-height: 30px;
                    letter-spacing: 0;
                    color: $color-2;
                    text-transform: none;
                    @include mappy-bp(mediumish) {
                        margin-bottom: 21px;
                        padding-top: 5px;
                        font-size: 29px;
                    }
                }

                a.button {
                    width: 167px;
                    height: 46px;
                    padding-top: 3px;
                    font-size: 12px;
                    @include mappy-bp(mediumish) {
                        width: 150px;
                        height: 40px;
                        padding-top: 0;
                        font-size: 11px;
                    }
                }

                ul {
                    margin-bottom: 18px;
                    padding: 0 18px;
                    @include mappy-bp(mediumish) {
                        margin-bottom: 29px;
                        padding: 0 20px;
                    }

                    li {
                        margin-bottom: 2px;
                        font-size: 12px;
                        line-height: 20px;
                        font-weight: $font-normal;
                        @include mappy-bp(mediumish) {
                            margin-bottom: 0;
                            font-size: 15px;
                            line-height: 23px;
                        }

                        &:before {
                            width: 0.75em;
                            margin-right: 0;
                            font-size: 18px;
                            @include mappy-bp(mediumish) {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
        /********************************************/
    }
    /********************************************/
    .slick-dotted.slick-slider {
        margin-bottom: 0;
    }
    .slick-dots {
        bottom: 30px;
        width: 89%;
        margin-left: 28px;
        text-align: left;
        @include mappy-bp(large) {
            width: calc(100% - ((100% - 1120px)/2));
            margin-left: calc((100% - 1120px)/2);
        }
        @include mappy-bp(largeish) {
            width: calc(100% - ((100% - 1170px)/2));
            margin-left: calc((100% - 1170px)/2);
        }

        li {
            width: 35px;
            height: 5px;
            margin: 0;
            border: 1px solid $color-1;
            background-color: transparent;

            &.slick-active {
                background-color: $color-1;
            }
    
            button {
                &:before {
                    content: '';
                }
            }
        }
        
    }
}