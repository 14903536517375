/*********************************************
:: About Us Page                   
********************************************/
.about-container {
    .content-max-width {
        display: flex;
        flex-direction: column-reverse;
        padding: 0 20px;
        @include mappy-bp(mediumish) {
            flex-direction: row;
            padding-right: 0;
            padding-left: 20px;
        }
        @include mappy-bp(large largeish) {
            padding-right: 0;
            padding-left: 40px;
        }
        @include mappy-bp(largeish) {
            margin-bottom: 65px;
            padding-right: 0;
            padding-left: 135px;
        }
    }
    /********************************************/
    article {
        width: 100%;
        @include mappy-bp(mediumish) {
            width: 600px;
        }
        @include mappy-bp(large) {
            width: 700px;
        }
    }
    /********************************************/
    aside {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        @include mappy-bp(mediumish) {
            width: calc(100% - 600px);
            margin-bottom: 0;
        }
        @include mappy-bp(large) {
            width: calc(100% - 700px);
        }

        .slick-list {
            height: 470px !important;
            @include mappy-bp(mediumish) {
                height: 610px !important;
            }
        }

        .slick-arrow {
            width: 40px;
            height: 40px;

            &.slick-prev, &.slick-next {
                top: 20px;
                left: 0;
                z-index: 2;
                &:before {
                    content: none;
                }
            }

            &.slick-prev {
                background: url('/images/icons/about-prev-arrow.svg');
            }

            &.slick-next {
                top: 20px;
                left: 41px;
                background: url('/images/icons/about-next-arrow.svg');
            }
        }

        .slide {
            position: relative;
            display: block;
            width: 100%;
            height: 335px;
            margin: 0 auto 25px;

            background-size: cover !important;
            background-position: center !important;

            @include mappy-bp(smallish) {
                width: 605px;
            }
            @include mappy-bp(mediumish) {
                margin: 0 0 40px;
                height: 375px;
            }
        }

        .slide-text {
            position: relative;
            right: 0;
            bottom: -250px;
            left: 0;

            width: 280px;
            margin: 0 0 0 auto;
            padding: 23px 10px 20px 0;
            background-color: $color-3;
            @include mappy-bp(smallish mediumish) {
                bottom: -280px;
                width: 505px;
                margin: 0 auto;
            }
            @include mappy-bp(mediumish) {
                bottom: -327px;
                width: 340px;
                margin: 0 0 0 auto;
                padding: 43px 10px 40px 0;
            }
            @include mappy-bp(largeish) {
                width: 400px;
            }
            @include mappy-bp(1380px) {
                width: 505px;
            }

            h3 {
                position: relative;
                top: -3px;
                margin: 0 0 12px;
                padding-left: 25px;

                font-size: 20px;
                font-weight: $font-extrabold;
                letter-spacing: 2px;
                line-height: 30px;
                text-transform: uppercase;
                @include mappy-bp(mediumish) {
                    padding-left: 45px;
                    font-size: 24px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: -55px;
                    left: -10.2%;
                    width: 5px;
                    height: calc(100% + 140px);
                    border: 2px solid $color-3;
                    background-color: $color-3;
                    @include mappy-bp(mediumish) {
                        top: -82px;
                        height: calc(100% + 190px);
                    }
                }
            }

            p {
                margin-bottom: 35px;
                padding-left: 25px;

                font-size: 12px;
                font-weight: $font-normal;
                line-height: 15px;
                @include mappy-bp(mediumish) {
                    padding-left: 45px;
                }
            }

            a.read-more {
                position: relative;
                left: 25px;
                @include mappy-bp(mediumish) {
                    left: 45px;
                }
            }
        }
    }
    /********************************************/
}