.title-module {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 10px 0 40px;
    text-align: center;

    @include mappy-bp(mediumish) {
        // width: 39%;
        height: 80px;
        margin: 45px 0 90px;
        text-align: left;
    }

    .title-bg {
        position: absolute;
        width: 100%;
        height: 50px;
        background-color: $color-2;
        
        &:before {
            content: '';
        }

        @include mappy-bp(mediumish) {
            // width: 93.3%;
            width: 36.39%;
            height: 80px;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: -37px;
                width: 0;
                height: 0;
                border-right: 37px solid transparent;
                border-top: 80px solid $color-2;
            }
        }
    }

    .title-bg-overlay {
        position: absolute;
        top: 10px;
        z-index: -1;
        width: 100%;
        height: 50px;
        background-color: $color-3;
        
        &:before {
            content: '';
        }

        @include mappy-bp(mediumish) {
            // width: 94.3%;
            width: 36.8%;
            height: 80px;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: -37px;
                width: 0;
                height: 0;
                border-right: 37px solid transparent;
                border-top: 80px solid $color-3;
            }
        }
    }

    h1 {
        padding-top: 12px;
        padding-left: 0;

        font-size: 20px;
        font-weight: $font-bold;
        letter-spacing: 1.5px;
        line-height: 25px;
        text-transform: uppercase;
        
        @include mappy-bp(mediumish) {
            padding-top: 26px;
            font-size: 25px;
            line-height: 30px;
            padding-left: 20px;
        }
        @include mappy-bp(large) {
            padding-left: 40px;
        }
        @include mappy-bp(largeish) {
            padding-left: 135px;
        }
    }
}